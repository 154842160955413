export enum AbnFormStep {
  CONSENT = "ABN_CONSENT",
  COMPANY = "ABN_COMPANY",
  DIRECTOR = "ABN_DIRECTOR",
  BASKET = "ABN_BASKET",
  CONFIRM = "ABN_CONFIRM",
  CHARGE = "ABN_CHARGE",
  COMPLETE = "ABN_COMPLETE",
}

export const FORM_STEP_TO_PATH: Record<AbnFormStep, string> = {
  [AbnFormStep.CONSENT]: "before-started",
  [AbnFormStep.COMPANY]: "company",
  [AbnFormStep.DIRECTOR]: "director",
  [AbnFormStep.BASKET]: "card-details",
  [AbnFormStep.CONFIRM]: "confirm",
  [AbnFormStep.CHARGE]: "charge",
  [AbnFormStep.COMPLETE]: "complete",
};
