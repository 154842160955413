export enum AcnFormStep {
  BEFORE_STARTED = "ACN_BEFORE_STARTED",
  WHAT_TO_EXPECT = "ACN_WHAT_TO_EXPECT",
  COMPANY = "ACN_COMPANY",
  DIRECTOR = "ACN_DIRECTOR",
  BASKET = "ACN_BASKET",
  CONFIRM = "ACN_CONFIRM",
  CHARGE = "ACN_CHARGE",
  COMPLETE = "ACN_COMPLETE",
}

export const FORM_STEP_TO_PATH: Record<AcnFormStep, string> = {
  [AcnFormStep.BEFORE_STARTED]: "before-started",
  [AcnFormStep.WHAT_TO_EXPECT]: "what-to-expect",
  [AcnFormStep.COMPANY]: "company",
  [AcnFormStep.DIRECTOR]: "director",
  [AcnFormStep.BASKET]: "card-details",
  [AcnFormStep.CONFIRM]: "confirm",
  [AcnFormStep.CHARGE]: "charge",
  [AcnFormStep.COMPLETE]: "complete",
};
