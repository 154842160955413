import { reaction } from "mobx";
import { type CompanyStore } from "services/company/companyStore";
import { type CompanyAddressStore } from "services/companyAddress/gb/companyAddressStore";
import type { CompanyBusinessActivitiesStore } from "services/companyBusinessActivities/companyBusinessActivitiesStore";
import { type FormationExpressStore } from "services/formationExpress/formationExpressStore";
import { IssueKey, type IssuesStore } from "services/issues/issuesStore";
import { type PricingPlanStore } from "services/pricingPlan/gb/pricingPlanStore";
import { QuestionsStore } from "services/questions/questionsStore";
import { CompanyQuestion, ISSUE_FIELD_TO_QUESTION, QUESTION_TO_PRODUCT } from "./companyConstants";

export class CompanyQuestionsStore extends QuestionsStore<CompanyQuestion> {
  protected _issueKey = IssueKey.Company;
  protected _issueFieldsToQuestions = ISSUE_FIELD_TO_QUESTION;

  constructor(
    private readonly _companyStore: CompanyStore,
    private readonly _companyAddressStore: CompanyAddressStore,
    private readonly _companyBusinessActivitiesStore: CompanyBusinessActivitiesStore,
    private readonly _pricingPlanStore: PricingPlanStore,
    private readonly _formationExpressStore: FormationExpressStore,
    protected readonly _issuesStore: IssuesStore,
  ) {
    super(CompanyQuestion, _issuesStore);

    reaction(
      () => [this._companyStore.company?.pricingPlan, this._pricingPlanStore.pricingPlanProducts],
      this._filterStepsByPricingPlan.bind(this),
    );
  }

  protected _getRelevantQuestion(): CompanyQuestion | undefined {
    const { company } = this._companyStore;

    if (!company?.companyName) {
      return CompanyQuestion.Name;
    }

    if (!company.pricingPlan) {
      return CompanyQuestion.PricingPlan;
    }

    if (!this._companyAddressStore.address) {
      if (!this._formationExpressStore.needToShow) {
        return CompanyQuestion.Address;
      }

      return CompanyQuestion.SameDayRegistration;
    }

    if (!company.country) {
      return CompanyQuestion.Country;
    }

    if (!company.natureOfBusiness) {
      return CompanyQuestion.Description;
    }

    if (!this._companyBusinessActivitiesStore.hasActivities) {
      return CompanyQuestion.Activities;
    }

    if (!company.startsTrading) {
      return CompanyQuestion.Starting;
    }

    return undefined;
  }

  protected async _getNextQuestionKey(): Promise<CompanyQuestion> {
    const nextQuestionKey = await super._getNextQuestionKey();

    switch (nextQuestionKey) {
      case CompanyQuestion.SameDayRegistration:
        if (!this._formationExpressStore.needToShow) {
          return this._questionsArray[this.activeQuestionIndex + 2];
        }
        break;
    }

    return nextQuestionKey;
  }

  protected async _getPreviousQuestionKey(): Promise<CompanyQuestion> {
    const previousQuestionKey = await super._getPreviousQuestionKey();

    switch (previousQuestionKey) {
      case CompanyQuestion.SameDayRegistration:
        if (!this._formationExpressStore.needToShow) {
          return this._questionsArray[this.activeQuestionIndex - 2];
        }
        break;
    }

    return previousQuestionKey;
  }

  private _filterStepsByPricingPlan(): void {
    this._questionsArray = this._getQuestionsArray().filter((question) => {
      const product = QUESTION_TO_PRODUCT[question];

      if (!product || !this._pricingPlanStore.isProductIncluded(product)) {
        return true;
      }

      return false;
    });
  }
}
