import { type AnalyticsManager } from "services/analytics/analyticsManager";
import { type IssuesStore } from "services/issues/issuesStore";
import type { CompanyAbnQuestionsStore } from "services/questions/au/abn/companyAbnQuestionsStore";
import type { DirectorAbnQuestionsStore } from "services/questions/au/abn/directorAbnQuestionsStore";
import { RegisterFormStep } from "services/registerForm/registerFormStep";
import { RegisterFormSteps } from "services/registerForm/registerFormSteps";
import { RegisterFormStepsStoreBase } from "services/registerForm/registerFormStepsStoreBase";
import { AbnFormStep } from "./constants";

export class AbnRegisterFormStepsStore extends RegisterFormStepsStoreBase<AbnFormStep> {
  protected readonly _steps: RegisterFormSteps<AbnFormStep> = new RegisterFormSteps<AbnFormStep>([
    new RegisterFormStep(AbnFormStep.CONSENT, "How it works"),
    new RegisterFormStep(AbnFormStep.COMPANY, "About your business", this._companyQuestionsStore),
    new RegisterFormStep(AbnFormStep.DIRECTOR, "About you (The director)", this._directorQuestionsStore),
    new RegisterFormStep(AbnFormStep.BASKET, "Your basket"),
    new RegisterFormStep(AbnFormStep.CONFIRM, "Confirm and submit"),
    new RegisterFormStep(AbnFormStep.CHARGE, "Confirm and submit"),
    new RegisterFormStep(AbnFormStep.COMPLETE, "Complete"),
  ]);
  readonly confirmStep: AbnFormStep = AbnFormStep.CONFIRM;
  readonly competeStep: AbnFormStep = AbnFormStep.COMPLETE;

  constructor(
    private readonly _companyQuestionsStore: CompanyAbnQuestionsStore,
    private readonly _directorQuestionsStore: DirectorAbnQuestionsStore,
    protected readonly _issuesStore: IssuesStore,
    protected readonly _analyticsManager: AnalyticsManager,
  ) {
    super(_issuesStore, _analyticsManager);
  }
}
