import { type DirectorAddressStore } from "services/director/directorAddressStore";
import { type DirectorStore } from "services/director/directorStore";
import { IssueKey, type IssuesStore } from "services/issues/issuesStore";
import { QuestionsStore } from "services/questions/questionsStore";
import { DIRECTOR_FIELD_TO_QUESTION, DirectorAcnQuestion } from "./directorAcnConstants";

export class DirectorAcnQuestionsStore extends QuestionsStore<DirectorAcnQuestion> {
  protected _issueKey = IssueKey.Director;
  protected _issueFieldsToQuestions = DIRECTOR_FIELD_TO_QUESTION;

  constructor(
    private readonly _directorStore: DirectorStore,
    private readonly _directorAddressStore: DirectorAddressStore,
    protected readonly _issuesStore: IssuesStore,
  ) {
    super(DirectorAcnQuestion, _issuesStore);
  }

  protected _getRelevantQuestion(): DirectorAcnQuestion | undefined {
    const { mainDirector } = this._directorStore;

    if (!mainDirector) {
      return DirectorAcnQuestion.Intro;
    }

    if (!mainDirector.firstName || !mainDirector.lastName) {
      return DirectorAcnQuestion.Name;
    }

    if (!mainDirector.dob) {
      return DirectorAcnQuestion.Birthday;
    }

    const address = this._directorAddressStore.tryGetForDirector(mainDirector.id);
    if (!address) {
      return DirectorAcnQuestion.Address;
    }

    if (!mainDirector.tfn) {
      return DirectorAcnQuestion.TaxFileNumber;
    }

    return undefined;
  }
}
