import { AnnaError } from "@anna-money/anna-web-lib";

import { companyFormToCompanyUpdateData } from "helpers/forms/acn/company";
import type { CompanyFormValues } from "helpers/forms/acn/companyTypes";
import { CompanyStore } from "services/company/companyStore";
import { CompanyAddressStore } from "services/companyAddress/au/companyAddressStore";
import { CompanyProductsStore } from "services/companyProducts/companyProductsStore";
import {
  CompanyProduct,
  type DefaultCompanyProductDetailsData,
  RegisterBusinessNamePeriods,
} from "services/companyProducts/companyProductsTypes";
import { PricingPlanStore } from "services/pricingPlan/au/pricingPlanStore";
import { type PricingPlanAu } from "services/pricingPlan/pricingPlanTypes";

export enum CompanyAcnStepEntity {
  Name = "Name",
  BusinessName = "BusinessName",
  PricingPlan = "PricingPlan",
  Address = "Address",
}

export class CompanyAcnStepStore {
  constructor(
    private readonly _companyStore: CompanyStore,
    private readonly _companyAddressStore: CompanyAddressStore,
    private readonly _companyProductsStore: CompanyProductsStore,
    private readonly _pricingPlanStore: PricingPlanStore,
  ) {}

  async updateEntity(data: CompanyFormValues, entity: CompanyAcnStepEntity): Promise<void> {
    switch (entity) {
      case CompanyAcnStepEntity.Name:
        if (!this._companyStore.company) {
          await this._companyStore.createCompany(data.name, data.namePostfix);
        } else {
          await this._companyStore.updateName(data.name, data.namePostfix);
        }
        break;
      case CompanyAcnStepEntity.PricingPlan:
        await this._updatePricingPlan(data.pricingPlan);
        break;
      case CompanyAcnStepEntity.BusinessName:
        await this._updateBusinessName(data.businessName, data.businessNamePeriod);
        break;
      case CompanyAcnStepEntity.Address:
        await this._companyAddressStore.updateAddress(data.address);
        break;
      default:
        await this._companyStore.updateCompany(companyFormToCompanyUpdateData(data));
    }
  }

  private async _updateBusinessName(name?: string, period?: RegisterBusinessNamePeriods): Promise<void> {
    if (period === RegisterBusinessNamePeriods.None) {
      await this._updateProduct(CompanyProduct.RegisterBusinessName, false);
      return;
    }

    await this._updateProduct(CompanyProduct.RegisterBusinessName, true, { name, period });
  }

  private async _updateProduct(
    product: CompanyProduct,
    toggle: boolean,
    details?: DefaultCompanyProductDetailsData,
  ): Promise<void> {
    toggle
      ? await this._companyProductsStore.addProduct(product, details)
      : await this._companyProductsStore.deleteProduct(product);
  }

  private async _updatePricingPlan(pricingPlan?: PricingPlanAu): Promise<void> {
    if (!pricingPlan) {
      throw new AnnaError("No pricing plan selected");
    }

    await this._pricingPlanStore.updatePricingPlan(pricingPlan);
  }
}
