import { reaction } from "mobx";
import { type CompanyStore } from "services/company/companyStore";
import { DirectorAddressKey, type DirectorAddressStore } from "services/director/directorAddressStore";
import { type DirectorAttributesStore } from "services/director/directorAttributesStore";
import { type DirectorStore } from "services/director/directorStore";
import { IssueKey, type IssuesStore } from "services/issues/issuesStore";
import { type PricingPlanStore } from "services/pricingPlan/gb/pricingPlanStore";
import { QuestionsStore } from "services/questions/questionsStore";
import { DirectorQuestion, ISSUE_FIELD_TO_QUESTION, QUESTION_TO_PRODUCT } from "./directorConstants";

export class DirectorQuestionsStore extends QuestionsStore<DirectorQuestion> {
  protected _issueKey = IssueKey.Director;
  protected _issueFieldsToQuestions = ISSUE_FIELD_TO_QUESTION;

  constructor(
    private readonly _directorStore: DirectorStore,
    private readonly _directorAddressStore: DirectorAddressStore,
    private readonly _directorAttributesStore: DirectorAttributesStore,
    private readonly _companyStore: CompanyStore,
    private readonly _pricingPlanStore: PricingPlanStore,
    protected readonly _issuesStore: IssuesStore,
  ) {
    super(DirectorQuestion, _issuesStore);

    this._filterQuestions();

    reaction(
      () => [this._companyStore.company?.pricingPlan, this._pricingPlanStore.pricingPlanProducts],
      this._filterQuestions.bind(this),
    );
  }

  protected async _getNextQuestionKey(): Promise<DirectorQuestion> {
    const nextQuestionKey = await super._getNextQuestionKey();

    switch (nextQuestionKey) {
      case DirectorQuestion.PreviousAddress:
        if (!this._directorAddressStore.getPreviousAddressRequired(this._directorStore.getMainDirector().id)) {
          return this._questionsArray[this.activeQuestionIndex + 2];
        }
        break;
    }

    return nextQuestionKey;
  }

  protected async _getPreviousQuestionKey(): Promise<DirectorQuestion> {
    const prevQuestionKey = await super._getPreviousQuestionKey();

    switch (prevQuestionKey) {
      case DirectorQuestion.PreviousAddress:
        if (!this._directorAddressStore.getPreviousAddressRequired(this._directorStore.getMainDirector().id)) {
          return this._questionsArray[this.activeQuestionIndex - 2];
        }
        break;
    }

    return prevQuestionKey;
  }

  protected _getRelevantQuestion(): DirectorQuestion | undefined {
    const { mainDirector } = this._directorStore;

    if (!mainDirector?.firstName || !mainDirector?.lastName) {
      return DirectorQuestion.Name;
    }

    if (!mainDirector.dob) {
      return DirectorQuestion.Birthday;
    }

    if (!mainDirector.nationality) {
      return DirectorQuestion.Nationality;
    }

    const address = this._directorAddressStore.tryGetForDirector(mainDirector.id);
    const attributes = this._directorAttributesStore.getForDirector(mainDirector.id);
    const previousAddress = this._directorAddressStore.tryGetForDirector(mainDirector.id, DirectorAddressKey.Previous);
    if (!address || (!previousAddress && !attributes?.length)) {
      return DirectorQuestion.Address;
    }

    if (!attributes) {
      return DirectorQuestion.Attributes;
    }

    return undefined;
  }

  private _filterQuestions(): void {
    let questions = [...this._getQuestionsArray()];

    questions = questions.filter((question) => {
      const product = QUESTION_TO_PRODUCT[question];

      if (!product || !this._pricingPlanStore.isProductIncluded(product)) {
        return true;
      }

      return false;
    });

    this._questionsArray = questions;
  }
}
