export const getUrlParam = (paramName: string): string | undefined => {
  const searchParams = new URLSearchParams(window.location.search);
  const param = searchParams.get(paramName);

  if (!param) {
    return;
  }

  return param;
};

export const concatPath = (...paths: string[]): string => {
  return paths.reduce((result, path) => {
    let localPath = path;

    if (!localPath) {
      return result;
    }

    if (localPath[0] === "/") {
      localPath = localPath.slice(1);
    }

    if (localPath.slice(-1)[0] === "/") {
      localPath = localPath.slice(0, localPath.length - 1);
    }

    return result === "/" ? result + localPath : `${result}/${localPath}`;
  }, "/");
};

export const pathStartsWith = (path: string, subpath: string): boolean => {
  return path.split("/")[path[0] === "/" ? 1 : 0] === subpath;
};
