import { type ValidationApiError } from "@anna-money/anna-web-lib";
import { convertCamelCaseToSnake } from "helpers/serialization";
import { type BackendFormValidationError } from "types/form";

export const extractBackendValidationError = (error: ValidationApiError): BackendFormValidationError => {
  // @ts-expect-error todo fix for prorer works with getErrors
  const validationErrors = error._validationErrors;
  if (validationErrors) {
    const key = convertCamelCaseToSnake(validationErrors[0].id);
    switch (validationErrors[0].id) {
      case "postcode":
      case "town":
        return {
          address: {
            [key]: validationErrors[0].title,
          },
        };
    }
    return {
      [key]: validationErrors[0].title,
    };
  }

  return error.message;
};
