import { type ApiClient } from "@anna-money/anna-web-lib";

import {
  CompanyAcnUpdateData,
  type CompanyCreateData,
  CompanyData,
  CompanyListData,
  type CompanyUpdateDataType,
} from "services/company/companyTypes";

export class CompanyClient {
  private readonly _apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this._apiClient = apiClient;
  }

  async getCompany(): Promise<CompanyData | undefined> {
    const response = await this._apiClient.get("api/formations/companies");
    const companies = response.getData(CompanyListData);

    return companies.companies.length ? companies.companies[0] : undefined;
  }

  async createCompany(data?: CompanyCreateData): Promise<CompanyData> {
    const response = await this._apiClient.post("api/formations/companies", data);
    return response.getData(CompanyData);
  }

  async updateCompany(companyId: string, data: CompanyUpdateDataType): Promise<CompanyData> {
    const response = await this._apiClient.post(`api/formations/companies/${companyId}`, data);

    return response.getData(CompanyData);
  }

  async updateAcn(companyId: string, data: CompanyAcnUpdateData): Promise<CompanyData> {
    const response = await this._apiClient.post(`api/formations/companies/${companyId}/acn`, data);

    return response.getData(CompanyData);
  }
}
