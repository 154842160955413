import { type DirectorAddressStore } from "services/director/directorAddressStore";
import { type DirectorStore } from "services/director/directorStore";
import { type IssuesStore } from "services/issues/issuesStore";
import { QuestionsStore } from "services/questions/questionsStore";
import { DirectorAbnQuestion } from "./directorAbnConstants";

export class DirectorAbnQuestionsStore extends QuestionsStore<DirectorAbnQuestion> {
  constructor(
    private readonly _directorStore: DirectorStore,
    private readonly _directorAddressStore: DirectorAddressStore,
    protected readonly _issuesStore: IssuesStore,
  ) {
    super(DirectorAbnQuestion, _issuesStore);
  }

  protected _getRelevantQuestion(): DirectorAbnQuestion | undefined {
    const { mainDirector } = this._directorStore;

    if (!mainDirector?.firstName || !mainDirector.lastName) {
      return DirectorAbnQuestion.Name;
    }

    if (!mainDirector.dob) {
      return DirectorAbnQuestion.Birthday;
    }

    const address = this._directorAddressStore.tryGetForDirector(mainDirector.id);
    if (!address) {
      return DirectorAbnQuestion.Address;
    }

    if (!mainDirector.tfn) {
      return DirectorAbnQuestion.TaxFileNumber;
    }

    return undefined;
  }
}
