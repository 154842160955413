import { type DependencyList, useMemo } from "react";
import { useServices } from "appServices";
import { addressDataToForm } from "helpers/forms/address";
import { getBusinessActivitiesFormValues } from "helpers/forms/businessActivity";
import { isGbPricingPlan } from "helpers/pricingPlan";
import { type AddressData } from "services/address/addressTypes";
import { type CompanyData, CompanyUpdateData } from "services/company/companyTypes";
import type { CompanyBusinessActivityData } from "services/companyBusinessActivities/companyBusinessActivitiesTypes";
import {
  CompanyProduct,
  VirtualOfficeBillingPeriod,
  type VirtualOfficeProductDetailsData,
} from "services/companyProducts/companyProductsTypes";
import type { PricingPlanGb } from "services/pricingPlan/pricingPlanTypes";
import { type ServicesGb } from "servicesGb";
import { type CompanyFormValues } from "./companyTypes";

export const formatCompanyName = (company?: CompanyData): string => {
  if (!company) {
    return "";
  }

  return `${company?.companyName} ${company?.namePostfix}`;
};

export const getUseVirtualOffice = (
  company?: CompanyData,
  pricingPlanIncludesVirtualOffice?: boolean,
): boolean | undefined => {
  if (typeof company?.useVirtualOffice !== "undefined") {
    return company.useVirtualOffice;
  }

  if (pricingPlanIncludesVirtualOffice) {
    return true;
  }

  return undefined;
};

export const getVirtualOfficeBillingPeriod = (
  company?: CompanyData,
  virtualOfficeProductDetails?: VirtualOfficeProductDetailsData,
): VirtualOfficeBillingPeriod => {
  if (!company || !virtualOfficeProductDetails) {
    return VirtualOfficeBillingPeriod.Yearly;
  }

  return virtualOfficeProductDetails.billingPeriod;
};

const getPricingPlanFormValue = (company?: CompanyData): PricingPlanGb | undefined => {
  if (!company?.pricingPlan) {
    return;
  }

  if (!isGbPricingPlan(company.pricingPlan)) {
    return;
  }

  return company.pricingPlan;
};

export const companyDataToCompanyForm = (
  company?: CompanyData,
  address?: AddressData,
  businessActivities?: CompanyBusinessActivityData[],
  virtualOfficeProductDetails?: VirtualOfficeProductDetailsData,
  pricingPlanIncludesVirtualOffice?: boolean,
  hasSameDayRegistration?: boolean,
  hasPlusTaxes?: boolean,
  hasVatRegistration?: boolean,
  hasConfirmationStatement?: boolean,
): CompanyFormValues | undefined => {
  if (!company?.companyName || !company.namePostfix) {
    return;
  }

  return {
    name: company.companyName,
    namePostfix: company.namePostfix,
    pricingPlan: getPricingPlanFormValue(company),
    country: company.country ?? undefined,
    natureOfBusiness: company.natureOfBusiness ?? undefined,
    startsTrading: company.startsTrading ?? undefined,
    address: addressDataToForm(address),
    businessActivities: getBusinessActivitiesFormValues(businessActivities),
    useVirtualOffice: getUseVirtualOffice(company, pricingPlanIncludesVirtualOffice),
    virtualOfficeBillingPeriod: getVirtualOfficeBillingPeriod(company, virtualOfficeProductDetails),
    sameDayRegistration: hasSameDayRegistration || undefined,
    annaTaxes: hasPlusTaxes || undefined,
    vatRegistration: hasVatRegistration || undefined,
    confirmationStatement: hasConfirmationStatement || undefined,
  };
};

export const companyFormToCompanyUpdateData = (companyForm: CompanyFormValues): CompanyUpdateData => {
  return new CompanyUpdateData(
    companyForm.name,
    companyForm.namePostfix,
    companyForm.natureOfBusiness,
    companyForm.startsTrading,
    companyForm.country,
    companyForm.useVirtualOffice,
    undefined,
    undefined,
  );
};

export const useCompanyFormInitialValue = (deps: DependencyList = []): CompanyFormValues | undefined => {
  const { companyStore, companyAddressStore, companyBusinessActivitiesStore, companyProductsStore, pricingPlanStore } =
    useServices<ServicesGb>();

  return useMemo(
    () =>
      companyDataToCompanyForm(
        companyStore.company,
        companyAddressStore.address,
        companyBusinessActivitiesStore?.activities,
        companyProductsStore.getProductDetails(CompanyProduct.VirtualOffice),
        pricingPlanStore.isProductIncluded(CompanyProduct.VirtualOffice),
        companyProductsStore.hasProduct(CompanyProduct.SameDayRegistration),
        companyProductsStore.hasProduct(CompanyProduct.Taxes),
        companyProductsStore.hasProduct(CompanyProduct.VatRegistration),
        companyProductsStore.hasProduct(CompanyProduct.ConfirmationStatement),
      ),
    deps,
  );
};
