import { makeObservable, observable } from "mobx";
import { type DirectorStore } from "services/director/directorStore";
import { type IssuesStore } from "services/issues/issuesStore";
import { IdentityQuestion } from "services/questions/gb/identityConstants";
import { QuestionsStore } from "services/questions/questionsStore";
import { type SumsubApplicationStore } from "services/sumsub/sumsubApplicationStore";

type IdentityQuestionsState = "default" | "poa_check_error";

export class IdentityQuestionsStore extends QuestionsStore<IdentityQuestion> {
  private _state: IdentityQuestionsState = "default";

  constructor(
    private readonly _directorStore: DirectorStore,
    private readonly _sumsubStore: SumsubApplicationStore,
    protected readonly _issuesStore: IssuesStore,
  ) {
    super(IdentityQuestion, _issuesStore);

    makeObservable(this, {
      _state: observable,
    } as any);
  }

  get state(): IdentityQuestionsState {
    return this._state;
  }

  set state(value: IdentityQuestionsState) {
    this._state = value;
  }

  onOpenbankingCheckTimeout(): void {
    this.state = "poa_check_error";
    this.goToQuestion(IdentityQuestion.Poa);
  }

  async isLastQuestion(): Promise<boolean> {
    if ([IdentityQuestion.IdentityCompliance, IdentityQuestion.Poa].includes(this._activeQuestionKey)) {
      const poaIsRequired = await this._directorStore.poaIsRequired();
      return !poaIsRequired;
    }

    return await super.isLastQuestion();
  }

  protected async _getNextQuestionKey(): Promise<IdentityQuestion> {
    const nextQuestionKey = await super._getNextQuestionKey();

    switch (nextQuestionKey) {
      case IdentityQuestion.Poa:
        if (!(await this._directorStore.poaIsRequired())) {
          return this._questionsArray[this.activeQuestionIndex + 2];
        }
        break;
    }

    return nextQuestionKey;
  }

  protected async _getPreviousQuestionKey(): Promise<IdentityQuestion> {
    const prevQuestionKey = await super._getPreviousQuestionKey();

    switch (prevQuestionKey) {
      case IdentityQuestion.IdentityCompliance:
        return IdentityQuestion.Identity;
      case IdentityQuestion.Poa:
        if (!(await this._directorStore.poaIsRequired())) {
          return IdentityQuestion.Identity;
        }
    }

    return prevQuestionKey;
  }

  protected _getRelevantQuestion(): IdentityQuestion | undefined {
    if (this._sumsubStore.isPassedVerifications) {
      return IdentityQuestion.IdentityCompliance;
    }

    return this.firstQuestion;
  }
}
