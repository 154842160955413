import { ApiClient } from "@anna-money/anna-web-lib";
import { type NavigateFunction } from "react-router-dom";
import { Memoize } from "typescript-memoize";
import { AuthenticationHandler } from "./auth/authenticationApiHandler";
import { BaseServices, Services } from "./services";
import { AbnRegisterFormStepsStore } from "./services/abnRegisterForm/abnRegisterFormStepsStore";
import { AbnRegisterFormStore } from "./services/abnRegisterForm/abnRegisterFormStore";
import { AcnRegisterFormStepsStore } from "./services/acnRegisterForm/acnRegisterFormStepsStore";
import { AcnRegisterFormStore } from "./services/acnRegisterForm/acnRegisterFormStore";
import { CompanyAddressStore } from "./services/companyAddress/au/companyAddressStore";
import { CompanyAddressFormStore } from "./services/companyAddressForm/au/companyAddressFormStore";
import { FeatureStore } from "./services/feature/featureStore";
import { PricingPlanStore } from "./services/pricingPlan/au/pricingPlanStore";
import { PricingPlanFormStore } from "./services/pricingPlanForm/au/pricingPlanFormStore";
import { ProfileStore } from "./services/profile/profileStore";
import { CompanyAbnQuestionsStore } from "./services/questions/au/abn/companyAbnQuestionsStore";
import { DirectorAbnQuestionsStore } from "./services/questions/au/abn/directorAbnQuestionsStore";
import { CompanyAcnQuestionsStore } from "./services/questions/au/acn/companyAcnQuestionsStore";
import { DirectorAcnQuestionsStore } from "./services/questions/au/acn/directorAcnQuestionsStore";
import { SignUpPageStore } from "./services/sighUpPage/au/signUpPageStore";
import { CompanyAbnStepStore } from "./services/stepStore/au/companyAbnStepStore";
import { CompanyAcnStepStore } from "./services/stepStore/au/companyAcnStepStore";
import type { UserClient } from "./services/user/userClient";
import { UserStore } from "./services/user/userStore";
import type { FlowType } from "./services/user/userTypes";

export class ServicesAu extends Services {
  constructor(
    protected readonly _baseServices: BaseServices,
    readonly _authenticationHandler: AuthenticationHandler,
    readonly _apiClient: ApiClient,
    readonly _userClient: UserClient,
    readonly _userStore: UserStore,
    readonly _profileStore: ProfileStore,
    readonly _featureStore: FeatureStore,
    readonly _navigate: NavigateFunction,
  ) {
    super(
      _baseServices,
      _authenticationHandler,
      _apiClient,
      _userClient,
      _userStore,
      _profileStore,
      _featureStore,
      _navigate,
    );
  }

  @Memoize()
  get companyAddressStore(): CompanyAddressStore {
    return new CompanyAddressStore(this.companyStore, this.addressClient);
  }

  @Memoize()
  get companyAcnStepStore(): CompanyAcnStepStore {
    return new CompanyAcnStepStore(
      this.companyStore,
      this.companyAddressStore,
      this.companyProductsStore,
      this.pricingPlanStore,
    );
  }

  @Memoize()
  get companyAbnStepStore(): CompanyAbnStepStore {
    return new CompanyAbnStepStore(this.companyStore, this.companyBusinessActivitiesStore, this.companyProductsStore);
  }

  @Memoize()
  get pricingPlanStore(): PricingPlanStore {
    return new PricingPlanStore(this.companyStore);
  }

  @Memoize()
  get companyAcnQuestionsStore(): CompanyAcnQuestionsStore {
    return new CompanyAcnQuestionsStore(this.companyStore, this.companyAddressStore, this.issuesStore);
  }

  @Memoize()
  get companyAbnQuestionsStore(): CompanyAbnQuestionsStore {
    return new CompanyAbnQuestionsStore(this.companyStore, this.companyBusinessActivitiesStore, this.issuesStore);
  }

  @Memoize()
  get directorAcnQuestionsStore(): DirectorAcnQuestionsStore {
    return new DirectorAcnQuestionsStore(this.directorStore, this.directorAddressStore, this.issuesStore);
  }

  @Memoize()
  get directorAbnQuestionsStore(): DirectorAbnQuestionsStore {
    return new DirectorAbnQuestionsStore(this.directorStore, this.directorAddressStore, this.issuesStore);
  }

  @Memoize()
  get acnRegisterFormFormStepsStore(): AcnRegisterFormStepsStore {
    return new AcnRegisterFormStepsStore(
      this.companyAcnQuestionsStore,
      this.directorAcnQuestionsStore,
      this.issuesStore,
      this.analyticsManager,
    );
  }

  @Memoize()
  get abnRegisterFormFormStepsStore(): AbnRegisterFormStepsStore {
    return new AbnRegisterFormStepsStore(
      this.companyAbnQuestionsStore,
      this.directorAbnQuestionsStore,
      this.issuesStore,
      this.analyticsManager,
    );
  }

  createCompanyAddressFormStore = (): CompanyAddressFormStore => new CompanyAddressFormStore(this.companyAddressStore);

  createPricingPlanFormStore = (): PricingPlanFormStore => {
    return new PricingPlanFormStore(this.pricingPlanStore);
  };

  createAcnRegisterFormStore = (): AcnRegisterFormStore => {
    return new AcnRegisterFormStore(
      this.directorStore,
      this.companyStore,
      this.companyAddressStore,
      this.companyProductsStore,
      this.paymentMethodsStore,
      this.userStore,
      this.analyticsManager,
      this.acnRegisterFormFormStepsStore,
      this.incorporationStore,
      this.issuesStore,
      this.companyAcnStepStore,
    );
  };

  createAbnRegisterFormStore = (): AbnRegisterFormStore => {
    return new AbnRegisterFormStore(
      this.directorStore,
      this.companyStore,
      this.companyAddressStore,
      this.paymentMethodsStore,
      this.consentStore,
      this.companyBusinessActivitiesStore,
      this.businessActivitiesStore,
      this.userStore,
      this.analyticsManager,
      this.abnRegisterFormFormStepsStore,
      this.incorporationStore,
      this.issuesStore,
      this.companyAbnStepStore,
    );
  };

  createSignUpPageStore = (flow: FlowType): SignUpPageStore =>
    new SignUpPageStore(
      flow,
      this.companyClient,
      this.leadClient,
      this.analyticsManager,
      this.userStore,
      this._navigate,
      this.config,
    );
}
