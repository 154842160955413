import { FormUpdateMethod } from "helpers/decorators/formUpdateMethod";
import { type CompanyFormValues } from "helpers/forms/incorporation/companyTypes";
import { CompanyAddressStore } from "services/companyAddress/au/companyAddressStore";
import { FormError } from "types/errors";
import type { BackendFormValidationError } from "types/form";

export type CompanyAddressFormValues = Pick<CompanyFormValues, "address">;

export class CompanyAddressFormStore {
  constructor(private readonly _companyAddressStore: CompanyAddressStore) {}

  @FormUpdateMethod()
  async onSubmit(values: CompanyAddressFormValues): Promise<BackendFormValidationError> {
    if (values.address) {
      await this._companyAddressStore.updateAddress(values.address);
      return;
    }

    throw new FormError("No address selected");
  }
}
