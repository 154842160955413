import { type IssueFieldQuestionMap } from "services/issues/issuesStore";

export enum DirectorAcnQuestion {
  Intro = "DIRECTOR_INTRO",
  Name = "DIRECTOR_NAME",
  Birthday = "DIRECTOR_BIRTHDAY",
  Address = "DIRECTOR_ADDRESS",
  TaxFileNumber = "DIRECTOR_TAX_FILE_NUMBER",
}

export const DIRECTOR_FIELD_TO_QUESTION: IssueFieldQuestionMap<DirectorAcnQuestion> = {
  dob: DirectorAcnQuestion.Birthday,
  first_name: DirectorAcnQuestion.Name,
  middle_names: DirectorAcnQuestion.Name,
  last_name: DirectorAcnQuestion.Name,
  address: DirectorAcnQuestion.Address,
  service_address: DirectorAcnQuestion.Address,
};

export const DIRECTOR_FIELD_TO_PATH: Record<DirectorAcnQuestion, string> = {
  [DirectorAcnQuestion.Intro]: "intro",
  [DirectorAcnQuestion.Name]: "name",
  [DirectorAcnQuestion.Birthday]: "birthday",
  [DirectorAcnQuestion.Address]: "address",
  [DirectorAcnQuestion.TaxFileNumber]: "tfn",
};
