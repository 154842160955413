import { type CompanyAbnFormValues } from "helpers/forms/abn/companyTypes";
import type { ConsentFormValues } from "helpers/forms/consentTypes";
import { type DirectorFormValues } from "helpers/forms/directorTypes";
import { type AnalyticsManager } from "services/analytics/analyticsManager";
import type { BusinessActivitiesStore } from "services/businessActivities/businessActivitiesStore";
import { type CompanyStore } from "services/company/companyStore";
import { type CompanyAddressStore } from "services/companyAddress/au/companyAddressStore";
import type { CompanyBusinessActivitiesStore } from "services/companyBusinessActivities/companyBusinessActivitiesStore";
import type { ConsentStore } from "services/consent/consentStore";
import { type DirectorEntity, type DirectorStore } from "services/director/directorStore";
import { type IncorporationStore } from "services/incorporation/incorporationStore";
import { type IssuesStore } from "services/issues/issuesStore";
import { type PaymentMethodsStore } from "services/paymentMethods/paymentMethodsStore";
import { RegisterFormStoreBase } from "services/registerForm/registerFormStoreBase";
import { CompanyAbnStepEntity, type CompanyAbnStepStore } from "services/stepStore/au/companyAbnStepStore";
import type { UserStore } from "services/user/userStore";
import { FlowType } from "services/user/userTypes";
import type { AbnRegisterFormStepsStore } from "./abnRegisterFormStepsStore";
import { AbnFormStep } from "./constants";

export class AbnRegisterFormStore extends RegisterFormStoreBase<AbnFormStep> {
  protected _flowType: FlowType = FlowType.Abn;

  constructor(
    private readonly _directorStore: DirectorStore,
    private readonly _companyStore: CompanyStore,
    private readonly _companyAddressStore: CompanyAddressStore,
    private readonly _paymentMethodsStore: PaymentMethodsStore,
    private readonly _consentStore: ConsentStore,
    private readonly _companyBusinessActivitiesStore: CompanyBusinessActivitiesStore,
    private readonly _businessActivitiesStore: BusinessActivitiesStore,
    protected readonly _userStore: UserStore,
    protected readonly _analyticsManager: AnalyticsManager,
    protected readonly _stepsStore: AbnRegisterFormStepsStore,
    protected readonly _incorporationStore: IncorporationStore,
    protected readonly _companyIssuesStore: IssuesStore,
    protected readonly _companyStepStore: CompanyAbnStepStore,
  ) {
    super(_stepsStore, _userStore, _incorporationStore, _companyIssuesStore, _analyticsManager);
  }

  protected async _init(): Promise<void> {
    await this._companyStore.init();

    await Promise.all([
      this._consentStore.init(),
      this._companyAddressStore.init(),
      this._companyBusinessActivitiesStore.init(),
      this._directorStore.init(),
      this._businessActivitiesStore.init(),
      this._companyIssuesStore.init(),
      this._incorporationStore.init(),
      this._paymentMethodsStore.init(),
    ]);
  }

  protected _getRelevantStep(): AbnFormStep {
    if (this._directorStore.mainDirector) {
      return AbnFormStep.DIRECTOR;
    }

    if (this._companyStore.company) {
      return AbnFormStep.COMPANY;
    }

    return AbnFormStep.CONSENT;
  }

  protected async _updateStepData(data: unknown, entity?: unknown): Promise<void> {
    switch (this._stepsStore.activeStep.type) {
      case AbnFormStep.CONSENT: {
        await this._consentStore.updateConsents(data as ConsentFormValues);
        break;
      }
      case AbnFormStep.COMPANY:
        await this._companyStepStore.updateEntity(data as CompanyAbnFormValues, entity as CompanyAbnStepEntity);
        break;
      case AbnFormStep.DIRECTOR: {
        await this._directorStore.updateMainDirector(data as DirectorFormValues, entity as DirectorEntity);
        break;
      }
      case AbnFormStep.CHARGE:
        await this._createIncorporation();
        break;
    }
  }
}
