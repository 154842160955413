import type { IssuesStore } from "services/issues/issuesStore";
import { BasketQuestion } from "services/questions/gb/basketConstants";
import { QuestionsStore } from "services/questions/questionsStore";

export class BasketQuestionsStore extends QuestionsStore<BasketQuestion> {
  constructor(protected readonly _issuesStore: IssuesStore) {
    super(BasketQuestion, _issuesStore);
  }

  _getRelevantQuestion(): BasketQuestion {
    return this.firstQuestion;
  }
}
