import { ApiClient } from "@anna-money/anna-web-lib";
import { type NavigateFunction } from "react-router-dom";
import { Memoize } from "typescript-memoize";
import { AuthenticationHandler } from "./auth/authenticationApiHandler";
import { BaseServices, Services } from "./services";
import { BillingItemsStore } from "./services/billingItems/billingItemsStore";
import { CheckoutBasketStore } from "./services/checkoutBasket/checkoutBasketStore";
import { CompanyAddressStore } from "./services/companyAddress/gb/companyAddressStore";
import { CompanyAddressFormStore } from "./services/companyAddressForm/gb/companyAddressFormStore";
import { FeatureStore } from "./services/feature/featureStore";
import { IncorporationRegisterFormStepsStore } from "./services/incorporationRegisterForm/incorporationRegisterFormStepsStore";
import { IncorporationRegisterFormStore } from "./services/incorporationRegisterForm/incorporationRegisterFormStore";
import { PricingPlanClient } from "./services/pricingPlan/gb/pricingPlanClient";
import { PricingPlanStore } from "./services/pricingPlan/gb/pricingPlanStore";
import { PricingPlanFormStore } from "./services/pricingPlanForm/gb/pricingPlanFormStore";
import { ProfileStore } from "./services/profile/profileStore";
import { CompanyQuestionsStore } from "./services/questions/gb/companyQuestionsStore";
import { DirectorQuestionsStore } from "./services/questions/gb/directorQuestionsStore";
import { RecommendedSicCodesStore } from "./services/sicCodes/recommendedSicCodesStore";
import { SicCodesClient } from "./services/sicCodes/sicCodesClient";
import { SignUpPageStore } from "./services/sighUpPage/gb/signUpPageStore";
import { CompanyStepStore } from "./services/stepStore/gb/companyStepStore";
import { ParticularsStepStore } from "./services/stepStore/gb/particularsStepStore";
import type { UserClient } from "./services/user/userClient";
import { UserStore } from "./services/user/userStore";

export class ServicesGb extends Services {
  constructor(
    protected readonly _baseServices: BaseServices,
    protected readonly _authenticationHandler: AuthenticationHandler,
    protected readonly _apiClient: ApiClient,
    protected readonly _userClient: UserClient,
    protected readonly _userStore: UserStore,
    protected readonly _profileStore: ProfileStore,
    protected readonly _featureStore: FeatureStore,
    protected readonly _navigate: NavigateFunction,
  ) {
    super(
      _baseServices,
      _authenticationHandler,
      _apiClient,
      _userClient,
      _userStore,
      _profileStore,
      _featureStore,
      _navigate,
    );
  }

  @Memoize()
  get billingItemsStore(): BillingItemsStore {
    return new BillingItemsStore();
  }

  @Memoize()
  get companyAddressStore(): CompanyAddressStore {
    return new CompanyAddressStore(
      this.companyStore,
      this.addressClient,
      this.companyProductsStore,
      this.pricingPlanStore,
    );
  }

  @Memoize()
  get companyStepStore(): CompanyStepStore {
    return new CompanyStepStore(
      this.companyStore,
      this.companyAddressStore,
      this.companyProductsStore,
      this.pricingPlanStore,
    );
  }

  @Memoize()
  get companyQuestionsStore(): CompanyQuestionsStore {
    return new CompanyQuestionsStore(
      this.companyStore,
      this.companyAddressStore,
      this.companyBusinessActivitiesStore,
      this.pricingPlanStore,
      this.formationExpressStore,
      this.issuesStore,
    );
  }

  @Memoize()
  get directorQuestionsStore(): DirectorQuestionsStore {
    return new DirectorQuestionsStore(
      this.directorStore,
      this.directorAddressStore,
      this.directorAttributesStore,
      this.companyStore,
      this.pricingPlanStore,
      this.issuesStore,
    );
  }

  @Memoize()
  get particularsStepStore(): ParticularsStepStore {
    return new ParticularsStepStore(this.directorStore, this.companyStore);
  }

  @Memoize()
  get pricingPlanStore(): PricingPlanStore {
    return new PricingPlanStore(
      this.companyStore,
      this.companyProductsStore,
      this.userStore,
      new PricingPlanClient(this.apiClient),
    );
  }

  @Memoize()
  get registerFormStepsStore(): IncorporationRegisterFormStepsStore {
    return new IncorporationRegisterFormStepsStore(
      this.companyQuestionsStore,
      this.directorQuestionsStore,
      this.identityQuestionsStore,
      this.basketQuestionsStore,
      this.issuesStore,
      this.analyticsManager,
    );
  }

  @Memoize()
  get recommendedSicCodesStore(): RecommendedSicCodesStore {
    return new RecommendedSicCodesStore(this.companyStore, this.companyBusinessActivitiesStore, this.sicCodesClient);
  }

  @Memoize()
  get sicCodesClient(): SicCodesClient {
    return new SicCodesClient(this.apiClient);
  }

  createCompanyAddressFormStore = (): CompanyAddressFormStore => new CompanyAddressFormStore(this.companyAddressStore);

  createCheckoutBasketStore = (): CheckoutBasketStore =>
    new CheckoutBasketStore(
      this.companyStore,
      this.companyAddressStore,
      this.companyProductsStore,
      this.formationExpressStore,
      this.pricingPlanStore,
      this.analyticsManager,
    );

  createPricingPlanFormStore = (): PricingPlanFormStore => {
    return new PricingPlanFormStore(this.pricingPlanStore);
  };

  createIncorporationRegisterFormStore = (): IncorporationRegisterFormStore => {
    return new IncorporationRegisterFormStore(
      this.consentStore,
      this.directorStore,
      this.companyStore,
      this.companyAddressStore,
      this.businessActivitiesStore,
      this.companyBusinessActivitiesStore,
      this.companyProductsStore,
      this.particularsStepStore,
      this.paymentMethodsStore,
      this.sumsubStore,
      this.formationExpressStore,
      this.userStore,
      this.analyticsManager,
      this.registerFormStepsStore,
      this.incorporationStore,
      this.issuesStore,
      this.pricingPlanStore,
      this.companyStepStore,
    );
  };

  createSignUpPageStore = (): SignUpPageStore =>
    new SignUpPageStore(
      this.companyClient,
      this.leadClient,
      this.analyticsManager,
      this.userStore,
      this._navigate,
      this.config,
      this.cookieStorage,
    );
}
