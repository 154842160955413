export enum DirectorAbnQuestion {
  Name = "ABN_DIRECTOR_NAME",
  Birthday = "ABN_DIRECTOR_BIRTHDAY",
  Address = "ABN_DIRECTOR_ADDRESS",
  TaxFileNumber = "ABN_DIRECTOR_TAX_FILE_NUMBER",
}

export const DIRECTOR_FIELD_TO_PATH: Record<DirectorAbnQuestion, string> = {
  [DirectorAbnQuestion.Name]: "name",
  [DirectorAbnQuestion.Birthday]: "birthday",
  [DirectorAbnQuestion.Address]: "address",
  [DirectorAbnQuestion.TaxFileNumber]: "tfn",
};
