import { type FC, Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { Loader } from "components/Loader/Loader";
import { Page } from "pages";

const Element = lazy(async () => await import("../../components/AbnRegisterForm/AbnRegisterForm"));

export const AbnFormRoutes: FC = () => (
  <Routes>
    <Route
      index
      path="*"
      element={
        <Suspense fallback={<Loader size="l" />}>
          <Element path={Page.Abn} />
        </Suspense>
      }
    />
  </Routes>
);
