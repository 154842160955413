import { makeAutoObservable } from "mobx";

import type { AnalyticsManager } from "services/analytics/analyticsManager";
import { type CardDetailsClient } from "services/cardDetails/cardDetailsClient";

type CardDetailsState = "loading" | "error" | "success";

export class CardDetailsStore {
  private _state: CardDetailsState = "loading";

  constructor(
    private readonly _cardDetailtsClient: CardDetailsClient,
    private readonly _analyticsManager: AnalyticsManager,
  ) {
    makeAutoObservable(this);
  }

  get state(): CardDetailsState {
    return this._state;
  }

  private set state(value: CardDetailsState) {
    this._state = value;
  }

  async init(): Promise<void> {
    this.state = "loading";
    this._analyticsManager.event("check-stripe-requirements.loading");

    try {
      const checkResults = await this._cardDetailtsClient.checkStripeRequirements();

      if (checkResults.ready) {
        this.state = "success";

        this._analyticsManager.event("check-stripe-requirements.success");
      }
    } catch (e) {
      this.state = "error";
      this._analyticsManager.event("check-stripe-requirements.error");
    }
  }
}
