import { AnnaError } from "@anna-money/anna-web-lib";
import { makeObservable, observable } from "mobx";
import { type AnalyticsManager } from "services/analytics/analyticsManager";
import { type IssuesStore } from "services/issues/issuesStore";
import { RegisterFormStep } from "./registerFormStep";
import { RegisterFormSteps } from "./registerFormSteps";

export abstract class RegisterFormStepsStoreBase<T> {
  protected abstract readonly _steps: RegisterFormSteps<T>;
  abstract readonly confirmStep: T;
  abstract readonly competeStep: T;

  get steps(): RegisterFormSteps<T> {
    return this._steps;
  }

  get activeStep(): RegisterFormStep<T> {
    return this.steps.activeStep;
  }

  get activeStepIndex(): number {
    return this.steps.activeStepIndex;
  }

  get isFirstStep(): boolean {
    return this.steps.activeStepIndex === 0;
  }

  get isLastStep(): boolean {
    return this.steps.activeStepIndex === this.steps.total - 1;
  }

  protected constructor(
    protected readonly _issuesStore: IssuesStore,
    protected readonly _analyticsManager: AnalyticsManager,
  ) {
    makeObservable(this, {
      _steps: observable,
    } as any);
  }

  async goPreviousQuestion(): Promise<void> {
    const { controller } = this.activeStep;

    if (!controller || controller.isFirstQuestion) {
      this.setStep(this._getPreviousStep());
      return;
    }

    await controller.goBack();
  }

  async goNextQuestion(): Promise<void> {
    const { controller } = this.activeStep;

    if (!controller) {
      this._goNextStep();
      return;
    }

    if (!controller.hasIssues()) {
      (await controller.isLastQuestion()) ? this._goNextStep() : await controller.goNext();
      return;
    }

    await controller.resoveIssueIfNeeded();

    this.goToNextIssue();
  }

  getStepByName(question: string): T | undefined {
    for (const step of this._steps) {
      if (step.type === question || step.controller?.hasQuestionByName(question)) {
        return step.type;
      }
    }

    return undefined;
  }

  setStep(step: T): void {
    this._steps.activateStep(step);

    if (this._steps.activeStep.controller) {
      this._steps.activeStep.controller.goToRelevantQuestion();
    }

    this._analyticsManager.event("load-step", { name: step });
  }

  goToNextIssue(): void {
    for (const step of this._steps) {
      if (!step.controller) {
        continue;
      }

      if (step.controller.hasIssues()) {
        this.setStep(step.type);
        return;
      }
    }

    this.setStep(this.confirmStep);
  }

  protected _getPreviousStep(): T {
    if (!this._steps.previousStep) {
      throw new AnnaError("No previous step during attempt to go back");
    }

    return this._steps.previousStep.type;
  }

  private _goNextStep(): void {
    if (!this._steps.nextStep) {
      throw new AnnaError("No next step during attempt to go forward");
    }
    this.setStep(this._steps.nextStep.type);
  }
}
