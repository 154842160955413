import type { QuestionsStore } from "services/questions/questionsStore";

export class RegisterFormStep<T, V = unknown> {
  constructor(
    private readonly _type: T,
    private readonly _title: string,
    private readonly _controller?: QuestionsStore<V>,
  ) {}

  get type(): T {
    return this._type;
  }

  get title(): string {
    return this._title;
  }

  get controller(): QuestionsStore<V> | undefined {
    return this._controller;
  }
}
