export enum CompanyAbnQuestion {
  Acn = "ABN_COMPANY_ACN",
  StartDate = "ABN_COMPANY_START_DATE",
  BusinessActivity = "ABN_COMPANY_BUSINESS_ACTIVITY",
  AgriculturalOperations = "ABN_AGRICULTURAL_OPERATIONS",
}

export const COMPANY_ABN_FIELD_TO_PATH: Record<CompanyAbnQuestion, string> = {
  [CompanyAbnQuestion.Acn]: "acn",
  [CompanyAbnQuestion.StartDate]: "start-date",
  [CompanyAbnQuestion.BusinessActivity]: "business-activity",
  [CompanyAbnQuestion.AgriculturalOperations]: "agricultural-operations",
};
