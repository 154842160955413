import { type FC, Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { Loader } from "components/Loader/Loader";
import { FlowType } from "services/user/userTypes";

const Element = lazy(async () => await import("../../components/SignUp/au/SignUp"));
const AcnElement = (
  <Suspense fallback={<Loader size="l" />}>
    <Element flow={FlowType.Acn} />
  </Suspense>
);
const AbnElement = (
  <Suspense fallback={<Loader size="l" />}>
    <Element flow={FlowType.Abn} />
  </Suspense>
);

export const SignUpRoutes: FC = () => {
  return (
    <Routes>
      <Route index path="acn" element={AcnElement} />
      <Route index path="abn" element={AbnElement} />
      <Route index path="*" element={AcnElement} />
    </Routes>
  );
};
