import { AnnaError } from "@anna-money/anna-web-lib";
import { makeObservable, observable } from "mobx";
import { registerFormStepParam } from "auth/authPages";
import { isSomeEnum } from "helpers/enum";
import { getFromMapByKey } from "helpers/object";
import { getUrlParam } from "helpers/url";
import { type IssueFieldQuestionMap, type IssueKey, type IssuesStore } from "services/issues/issuesStore";

export abstract class QuestionsStore<T> {
  protected _questionsArray: T[] = this._getQuestionsArray();
  protected _activeQuestionKey: T = this._questionsArray[0];
  protected _issueKey?: IssueKey;
  protected _issueFieldsToQuestions?: IssueFieldQuestionMap<T>;
  protected abstract _getRelevantQuestion(): T | undefined;

  protected constructor(
    protected _questions: Object,
    protected readonly _issuesStore: IssuesStore,
  ) {
    makeObservable(this, {
      _activeQuestionKey: observable,
    } as any);
  }

  get activeQuestionKey(): T {
    return this._activeQuestionKey;
  }

  private set activeQuestionKey(value: T) {
    this._activeQuestionKey = value;
  }

  get activeQuestionIndex(): number {
    return this._questionsArray.indexOf(this.activeQuestionKey);
  }

  get firstQuestion(): T {
    return this._questionsArray[0];
  }

  get lastQuestion(): T {
    return this._questionsArray.slice(-1)[0];
  }

  get isFirstQuestion(): boolean {
    return this.activeQuestionIndex === 0;
  }

  get firstQuestionWithIssue(): T | undefined {
    if (!this._issueKey || !this._issueFieldsToQuestions) {
      return;
    }

    const issue = this._issuesStore.getFirstIssueForKey(this._issueKey);

    return getFromMapByKey(this._issueFieldsToQuestions, issue?.field);
  }

  hasQuestionByName(question: string): boolean {
    return isSomeEnum(this._questions)(question);
  }

  goToQuestion(question: T): void {
    this.activeQuestionKey = question;
  }

  hasIssues(): boolean {
    if (!this._issueKey) {
      return false;
    }

    return this._issuesStore.hasIssueForKey(this._issueKey);
  }

  goToRelevantQuestion(): void {
    this.goToQuestion(
      this._tryGetQuestionFromUrl() || this._getRelevantQuestion() || this.firstQuestionWithIssue || this.lastQuestion,
    );
  }

  async isLastQuestion(): Promise<boolean> {
    return this.activeQuestionIndex === this._questionsArray.length - 1;
  }

  async goBack(): Promise<void> {
    if (this.isFirstQuestion) {
      throw new AnnaError("Can‘t go back from the first question");
    }

    const prevQuestionKey = await this._getPreviousQuestionKey();

    this.goToQuestion(prevQuestionKey);
  }

  async goNext(): Promise<void> {
    if (await this.isLastQuestion()) {
      throw new AnnaError("Can‘t go forward from the last question");
    }

    const nextQuestionKey = await this._getNextQuestionKey();

    this.goToQuestion(nextQuestionKey);
  }

  async resoveIssueIfNeeded(): Promise<void> {
    if (!this._issueKey || !this._issueFieldsToQuestions) {
      return;
    }

    const issues = this._issuesStore.getIssuesForKey(this._issueKey);
    for (const issue of issues) {
      const question = getFromMapByKey(this._issueFieldsToQuestions, issue.field);
      if (question === this._activeQuestionKey) {
        await this._issuesStore.resolveIssue(issue.id);
      }
    }
  }

  protected async _getPreviousQuestionKey(): Promise<T> {
    return this._questionsArray[this.activeQuestionIndex - 1];
  }

  protected async _getNextQuestionKey(): Promise<T> {
    return this._questionsArray[this.activeQuestionIndex + 1];
  }

  protected _getQuestionsArray(): T[] {
    return Object.values(this._questions);
  }

  private _tryGetQuestionFromUrl(): T | undefined {
    const questionFromParams = getUrlParam(registerFormStepParam);
    if (questionFromParams && this.hasQuestionByName(questionFromParams)) {
      return questionFromParams as T;
    }

    return undefined;
  }
}
