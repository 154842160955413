import { addressFormToCreateData, addressFormToUpdateData } from "helpers/forms/address";
import { type AddressFormValue } from "helpers/forms/addressTypes";
import { type AddressClient, AddressKey } from "services/address/addressClient";
import { type AddressData } from "services/address/addressTypes";
import { type CompanyStore } from "services/company/companyStore";
import { type CompanyCountry, type CompanyData } from "services/company/companyTypes";
import { CompanyAddressStoreBase } from "services/companyAddress/companyAddressStoreBase";
import { type CompanyProductsStore } from "services/companyProducts/companyProductsStore";
import { CompanyProduct, type VirtualOfficeBillingPeriod } from "services/companyProducts/companyProductsTypes";
import { COUNTRY_NAME_TO_CODE } from "services/incorporationRegisterForm/constants";
import { type PricingPlanStore } from "services/pricingPlan/gb/pricingPlanStore";
import { FormError } from "types/errors";

export type UpdateAddressData =
  | {
      virtualOfficeBillingPeriod?: VirtualOfficeBillingPeriod;
    }
  | {
      address?: AddressFormValue;
    };

const resolveAddressToCountryCode = (country: string = ""): CompanyCountry | undefined => {
  return COUNTRY_NAME_TO_CODE[country.toUpperCase()];
};

export class CompanyAddressStore extends CompanyAddressStoreBase {
  constructor(
    protected readonly _companyStore: CompanyStore,
    protected readonly _addressClient: AddressClient,
    private readonly _companyProductStore: CompanyProductsStore,
    private readonly _pricingPlanStore: PricingPlanStore,
  ) {
    super(_companyStore, _addressClient);
  }

  get canAlterCountry(): boolean {
    const useVirtualOffice = this._companyStore.getCompany().useVirtualOffice;
    const country = (this.address?.country ?? "").toUpperCase();

    return country === "WALES" && !useVirtualOffice;
  }

  async updateAddress(data: UpdateAddressData): Promise<void> {
    let address, virtualOfficeBillingPeriod;

    if ("address" in data) {
      address = data.address;
    }

    if ("virtualOfficeBillingPeriod" in data) {
      virtualOfficeBillingPeriod = data.virtualOfficeBillingPeriod;
    }

    if (address) {
      await this._companyProductStore.deleteProduct(CompanyProduct.VirtualOffice);
      await this._updateAddress(false, address);
      return;
    }

    if (virtualOfficeBillingPeriod) {
      if (!this._pricingPlanStore.isProductIncluded(CompanyProduct.VirtualOffice)) {
        await this._companyProductStore.addProduct(CompanyProduct.VirtualOffice, virtualOfficeBillingPeriod);
      }
      await this._updateAddress(true);
      return;
    }

    throw new FormError("Please select address");
  }

  private async _updateAddress(useVirtualOffice: boolean, address?: AddressFormValue): Promise<void> {
    const company = this._companyStore.getCompany();
    const changeFromVirtual = Boolean(company.useVirtualOffice) && !useVirtualOffice;

    await this._companyStore.updateVirtualOffice(useVirtualOffice);

    // We need to update address after setting useVirtualOffice because in case of
    // useVirtualOffice=true it will be changed to Hoxton address and in case of
    // useVirtualOffice=false existed address will be deleted
    const updatedAddress = await this._addressClient.getAddress(AddressKey.Company, company.id);

    if (useVirtualOffice) {
      this.address = updatedAddress;
      return;
    }

    if (!address) {
      throw new FormError("Please select address");
    }

    this.address = await this._createOrUpdateAddress(changeFromVirtual, company, address, updatedAddress);

    const country = resolveAddressToCountryCode(this.address?.country);
    if (country) {
      await this._companyStore.updateCountry(country);
    }
  }

  private async _createOrUpdateAddress(
    changeFromVirtual: boolean,
    company: CompanyData,
    newAddress: AddressFormValue,
    currentAddress?: AddressData,
  ): Promise<AddressData> {
    // update or create address in address service
    // odd logic about when use create or update
    const handler =
      changeFromVirtual || !currentAddress
        ? async () =>
            await this._addressClient.createAddress(addressFormToCreateData(newAddress, { companyId: company.id }))
        : async () => await this._addressClient.updateAddress(currentAddress.id, addressFormToUpdateData(newAddress));
    return await handler();
  }
}
