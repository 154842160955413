import { type FC, type ReactNode, useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { useServices } from "appServices";
import { pathStartsWith } from "helpers/url";
import { Page } from "pages";
import { FlowType } from "services/user/userTypes";

interface UserFlowGuardProps {
  children: ReactNode;
}

export const UserFlowGuard: FC<UserFlowGuardProps> = ({ children }) => {
  const {
    userStore: { user, status },
    analyticsManager,
    config,
  } = useServices();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    analyticsManager.event("user-state", {
      canProceed: status.canProceed,
      companies: status.companies.join(),
      blockedByOnboarding: status.blockedByOnboarding,
    });

    if (!status.canProceed) {
      navigate(Page.ExistedCustomer);
      return;
    }

    if (config.isAU) {
      switch (user.currentFlowType) {
        case FlowType.Acn:
          if (!pathStartsWith(pathname, Page.Acn)) {
            navigate(Page.Acn);
          }
          break;
        case FlowType.Abn:
          if (!pathStartsWith(pathname, Page.Abn)) {
            navigate(Page.Abn);
          }
          break;
      }
    }
  }, []);

  return <>{children}</>;
};
