import { BusinessActivityData } from "services/businessActivities/businessActivitiesTypes";
import type { CompanyBusinessActivityData } from "services/companyBusinessActivities/companyBusinessActivitiesTypes";
import { addressDataToForm } from "./address";
import type { BusinessActivityFormValues } from "./businessActivityTypes";

export const businessActivityDataToFormValue = (businessActivity: BusinessActivityData): BusinessActivityFormValues => {
  return {
    id: businessActivity.id,
    code: businessActivity.code,
    description: businessActivity.description,
  };
};

export const companyBusinessActivityDataToFormValue = (
  companyBusinessActivity: CompanyBusinessActivityData,
): BusinessActivityFormValues => {
  return {
    id: companyBusinessActivity.businessActivityId,
    code: companyBusinessActivity.code,
    description: companyBusinessActivity.description,
    address: companyBusinessActivity.address ? addressDataToForm(companyBusinessActivity.address) : undefined,
  };
};

export const getBusinessActivitiesFormValues = (
  businessActivities?: CompanyBusinessActivityData[],
): BusinessActivityFormValues[] | undefined => {
  return businessActivities ? businessActivities.map(companyBusinessActivityDataToFormValue) : undefined;
};
