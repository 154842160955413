export enum CompanyAcnQuestion {
  Name = "ACN_COMPANY_NAME",
  PricingPlan = "ACN_COMPANY_PRICING_PLAN",
  Jurisdiction = "ACN_COMPANY_JURISDICTION",
  Address = "ACN_COMPANY_ADDRESS",
  Occupier = "ACN_COMPANY_OCCUPIER",
}

export const COMPANY_ACN_FIELD_TO_PATH: Record<CompanyAcnQuestion, string> = {
  [CompanyAcnQuestion.Name]: "name",
  [CompanyAcnQuestion.PricingPlan]: "pricing-plan",
  [CompanyAcnQuestion.Jurisdiction]: "jurisdiction",
  [CompanyAcnQuestion.Address]: "address",
  [CompanyAcnQuestion.Occupier]: "occupier",
};
