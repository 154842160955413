import { type CompanyStore } from "services/company/companyStore";
import type { CompanyBusinessActivitiesStore } from "services/companyBusinessActivities/companyBusinessActivitiesStore";
import { type IssuesStore } from "services/issues/issuesStore";
import { QuestionsStore } from "services/questions/questionsStore";
import { CompanyAbnQuestion } from "./companyAbnConstants";

export class CompanyAbnQuestionsStore extends QuestionsStore<CompanyAbnQuestion> {
  constructor(
    private readonly _companyStore: CompanyStore,
    private readonly _companyBusinessActivitiesStore: CompanyBusinessActivitiesStore,
    protected readonly _issuesStore: IssuesStore,
  ) {
    super(CompanyAbnQuestion, _issuesStore);
  }

  protected _getRelevantQuestion(): CompanyAbnQuestion | undefined {
    const { company } = this._companyStore;

    if (!company?.acn) {
      return CompanyAbnQuestion.Acn;
    }

    if (!company.abnStartDate) {
      return CompanyAbnQuestion.StartDate;
    }

    if (!this._companyBusinessActivitiesStore.hasActivities) {
      return CompanyAbnQuestion.BusinessActivity;
    }

    if (!company.isConductAgriculturalPropertyOperations) {
      return CompanyAbnQuestion.AgriculturalOperations;
    }

    return undefined;
  }
}
