import { makeAutoObservable } from "mobx";

import { companyFormToCompanyUpdateData } from "helpers/forms/incorporation/company";
import type { CompanyFormValues } from "helpers/forms/incorporation/companyTypes";
import { CompanyStore } from "services/company/companyStore";
import { CompanyAddressStore } from "services/companyAddress/gb/companyAddressStore";
import { CompanyProductsStore } from "services/companyProducts/companyProductsStore";
import {
  CompanyProduct,
  type DefaultCompanyProductDetailsData,
  VirtualOfficeBillingPeriod,
} from "services/companyProducts/companyProductsTypes";
import { PricingPlanStore } from "services/pricingPlan/gb/pricingPlanStore";
import { type PricingPlanGb } from "services/pricingPlan/pricingPlanTypes";
import { FormError } from "types/errors";

export enum CompanyStepEntity {
  Name = "Name",
  PricingPlan = "PricingPlan",
  SameDayRegistration = "SameDayRegistration",
  Address = "Address",
  Country = "Country",
  AnnaTaxes = "AnnaTaxes",
  VatRegistration = "VatRegistration",
  ConfirmationStatement = "ConfirmationStatement",
}

export class CompanyStepStore {
  constructor(
    private readonly _companyStore: CompanyStore,
    private readonly _companyAddressStore: CompanyAddressStore,
    private readonly _companyProductsStore: CompanyProductsStore,
    private readonly _pricingPlanStore: PricingPlanStore,
  ) {
    makeAutoObservable(this);
  }

  async updateEntity(data: CompanyFormValues, entity: CompanyStepEntity): Promise<void> {
    switch (entity) {
      case CompanyStepEntity.Name:
        if (!this._companyStore.company) {
          await this._companyStore.createCompany(data.name, data.namePostfix);
        } else {
          await this._companyStore.updateName(data.name, data.namePostfix);
        }
        break;
      case CompanyStepEntity.PricingPlan:
        await this._updatePricingPlan(data.pricingPlan);
        break;
      case CompanyStepEntity.SameDayRegistration:
        await this._updateProduct(CompanyProduct.SameDayRegistration, Boolean(data.sameDayRegistration));
        break;
      case CompanyStepEntity.Address:
        await this._companyAddressStore.updateAddress(
          data.useVirtualOffice
            ? { virtualOfficeBillingPeriod: data.virtualOfficeBillingPeriod }
            : { address: data.address },
        );
        break;
      case CompanyStepEntity.Country:
        await this._companyStore.updateCountry(data.country);
        break;
      case CompanyStepEntity.AnnaTaxes:
        await this._updateProduct(CompanyProduct.Taxes, Boolean(data.annaTaxes));
        break;
      case CompanyStepEntity.VatRegistration:
        await this._updateProduct(CompanyProduct.VatRegistration, Boolean(data.vatRegistration));
        break;
      case CompanyStepEntity.ConfirmationStatement:
        await this._updateProduct(CompanyProduct.ConfirmationStatement, Boolean(data.confirmationStatement));
        break;
      default:
        await this._companyStore.updateCompany(companyFormToCompanyUpdateData(data));
    }
  }

  private async _updateProduct(
    product: CompanyProduct,
    toggle: boolean,
    details?: DefaultCompanyProductDetailsData,
  ): Promise<void> {
    toggle
      ? await this._companyProductsStore.addProduct(product, details)
      : await this._companyProductsStore.deleteProduct(product);
  }

  private async _updatePricingPlan(pricingPlan?: PricingPlanGb): Promise<void> {
    if (!pricingPlan) {
      throw new FormError("No pricing plan selected");
    }

    await this._pricingPlanStore.updatePricingPlan(pricingPlan);

    if (this._pricingPlanStore.isProductIncluded(CompanyProduct.VirtualOffice)) {
      await this._companyAddressStore.updateAddress({ virtualOfficeBillingPeriod: VirtualOfficeBillingPeriod.Yearly });
    }
  }
}
