import { JsonProperty, Serializable } from "@anna-money/anna-web-lib";
import { PricingPlan } from "services/pricingPlan/pricingPlanTypes";

export enum CompanyNamePostfix {
  Ltd = "Ltd",
  Limited = "Limited",
  // AU
  PtyLtdV1 = "PTY LTD",
  PtyLtdV2 = "PTY. LTD.",
  PtyLtdV3 = "PTY. LTD",
  PtyLtdV4 = "PTY LTD.",
  PtyLimitedV1 = "PTY. LIMITED",
  PtyLimitedV2 = "PTY LIMITED",
  ProprietaryLtdV1 = "PROPRIETARY LTD.",
  ProprietaryLtdV2 = "PROPRIETARY LTD",
  ProprietaryLimited = "PROPRIETARY LIMITED",
}

export enum CompanyCountry {
  EW = "EW",
  SC = "SC",
  WA = "WA",
  NI = "NI",
  AUS = "AUS",
}

export enum AustralianState {
  AustralianCapitalTerritory = "Australian Capital Territory",
  NewSouthWales = "New South Wales",
  NorthernTerritory = "Northern Territory",
  Queensland = "Queensland",
  SouthAustralia = "South Australia",
  Tasmania = "Tasmania",
  Victoria = "Victoria",
  WesternAustralia = "Western Australia",
}

@Serializable()
export class CompanyData {
  constructor(
    @JsonProperty() readonly id: string,
    @JsonProperty() readonly alias: string,
    @JsonProperty() readonly created: string,
    @JsonProperty() readonly updated: string,
    @JsonProperty({ required: false, type: String }) readonly companyName: Nullable<string>,
    @JsonProperty({ required: false, type: CompanyNamePostfix }) readonly namePostfix: Nullable<CompanyNamePostfix>,
    @JsonProperty({ required: false, type: PricingPlan }) readonly pricingPlan: Nullable<PricingPlan>,
    @JsonProperty({ required: false, type: CompanyCountry }) readonly country: Nullable<CompanyCountry>,
    @JsonProperty({ required: false, type: String }) readonly natureOfBusiness: Nullable<string>,
    @JsonProperty({ required: false, type: String }) readonly startsTrading: Nullable<string>,
    @JsonProperty({ required: false, type: String }) readonly managingTaxes: Nullable<string>,
    @JsonProperty({ required: false, type: Boolean }) readonly useVirtualOffice: boolean,
    @JsonProperty({ required: false, type: String }) readonly poaFileId: Nullable<string>,
    @JsonProperty({ required: false, type: String }) readonly registeredWithDirectorId: Nullable<string>,
    @JsonProperty({ required: false, type: Number }) readonly totalCapitalGbp: Nullable<number>,
    @JsonProperty({ required: false, type: Number }) readonly totalSharesNum: Nullable<number>,
    // AU
    @JsonProperty({ required: false, type: AustralianState }) readonly stateOfRegistration: Nullable<AustralianState>,
    @JsonProperty({ required: false, type: String }) readonly acn: Nullable<string>,
    @JsonProperty({ required: false, type: String }) readonly abnStartDate: Nullable<string>,
    @JsonProperty({ required: false, type: Boolean })
    readonly isConductAgriculturalPropertyOperations: Nullable<boolean>,
  ) {}
}

@Serializable()
export class CompanyListData {
  constructor(@JsonProperty({ type: CompanyData, isArray: true }) readonly companies: CompanyData[]) {}
}

@Serializable()
export class CompanyCreateData {
  constructor(
    @JsonProperty() readonly companyName: string,
    @JsonProperty({ type: CompanyNamePostfix }) readonly namePostfix: CompanyNamePostfix,
  ) {}
}

@Serializable()
export class CompanyUpdateData {
  constructor(
    @JsonProperty() readonly companyName: string,
    @JsonProperty({ type: CompanyNamePostfix }) readonly namePostfix: CompanyNamePostfix,
    // GB
    @JsonProperty({ required: false, type: String }) readonly natureOfBusiness: Nullable<string>,
    @JsonProperty({ required: false, type: String }) readonly startsTrading: Nullable<string>,
    @JsonProperty({ required: false, type: CompanyCountry }) readonly country: Nullable<CompanyCountry>,
    @JsonProperty({ required: false, type: Boolean }) readonly useVirtualOffice: Nullable<boolean>,
    // AU
    @JsonProperty({ required: false, type: AustralianState }) readonly stateOfRegistration: Nullable<AustralianState>,
    @JsonProperty({ required: false, type: String }) readonly abnStartDate: Nullable<string>,
  ) {}
}

@Serializable()
export class CompanyNameUpdateData {
  constructor(
    @JsonProperty() readonly companyName: string,
    @JsonProperty({ required: false, type: CompanyNamePostfix }) readonly namePostfix: Nullable<CompanyNamePostfix>,
  ) {}
}

@Serializable()
export class CompanyPoaUpdateData {
  constructor(@JsonProperty() readonly poaFileId: string) {}
}

@Serializable()
export class CompanyUseVirtualOfficeUpdateData {
  constructor(@JsonProperty({ type: Boolean }) readonly useVirtualOffice: boolean) {}
}

@Serializable()
export class CompanyCountryUpdateData {
  constructor(@JsonProperty({ type: CompanyCountry }) readonly country: CompanyCountry) {}
}

@Serializable()
export class CompanySharesUpdateData {
  constructor(
    @JsonProperty({ type: Number }) readonly totalSharesNum: number,
    @JsonProperty({ type: Number }) readonly totalCapitalGbp: number,
  ) {}
}

@Serializable()
export class CompanyPricingPlanUpdateData {
  constructor(@JsonProperty({ type: PricingPlan }) readonly pricingPlan: PricingPlan) {}
}

@Serializable()
export class CompanyAbnStartDateUpdateData {
  constructor(@JsonProperty() readonly abnStartDate: string) {}
}

@Serializable()
export class CompanyAcnUpdateData {
  constructor(@JsonProperty() readonly acn: string) {}
}

@Serializable()
export class CompanyAgriculturalPropertyOperationsUpdateData {
  constructor(@JsonProperty({ type: Boolean }) readonly isConductAgriculturalPropertyOperations: boolean) {}
}

export type CompanyUpdateDataType =
  | CompanyUpdateData
  | CompanyNameUpdateData
  | CompanyPoaUpdateData
  | CompanyUseVirtualOfficeUpdateData
  | CompanyCountryUpdateData
  | CompanySharesUpdateData
  | CompanyPricingPlanUpdateData
  | CompanyAbnStartDateUpdateData
  | CompanyAgriculturalPropertyOperationsUpdateData;
