import { UnexpectedValueError } from "@anna-money/anna-web-lib";
import { formatDateYYYYMMDD } from "helpers/date";
import type { CompanyAbnFormValues } from "helpers/forms/abn/companyTypes";
import { CompanyStore } from "services/company/companyStore";
import type { CompanyBusinessActivitiesStore } from "services/companyBusinessActivities/companyBusinessActivitiesStore";
import type { CompanyProductsStore } from "services/companyProducts/companyProductsStore";
import { CompanyProduct } from "services/companyProducts/companyProductsTypes";
import { FormError } from "types/errors";

export enum CompanyAbnStepEntity {
  Acn = "Acn",
  StartDate = "StartDate",
  BusinessActivity = "BusinessActivity",
  AgriculturalOperations = "AgriculturalOperations",
}

export class CompanyAbnStepStore {
  constructor(
    private readonly _companyStore: CompanyStore,
    private readonly _companyBusinessActivitiesStore: CompanyBusinessActivitiesStore,
    private readonly _companyProductsStore: CompanyProductsStore,
  ) {}

  async updateEntity(data: CompanyAbnFormValues, entity: CompanyAbnStepEntity): Promise<void> {
    switch (entity) {
      case CompanyAbnStepEntity.Acn:
        await this._createCompanyIfNeeded();
        await this._updateAcn(data);
        break;
      case CompanyAbnStepEntity.StartDate:
        await this._updateAbnStartDate(data);
        break;
      case CompanyAbnStepEntity.BusinessActivity:
        await this._updateBusinessActivity(data);
        break;
      case CompanyAbnStepEntity.AgriculturalOperations:
        await this._updateAgriculturalOperations(data);
        break;
      default:
        throw new UnexpectedValueError("entity", entity);
    }
  }

  private async _createCompanyIfNeeded(): Promise<void> {
    if (!this._companyStore.company) {
      await this._companyStore.createCompany();
    }
    if (!this._companyProductsStore.hasProduct(CompanyProduct.AbnRegistration)) {
      await this._companyProductsStore.addProduct(CompanyProduct.AbnRegistration);
    }
  }

  private async _updateAcn(data: CompanyAbnFormValues): Promise<void> {
    if (!data.acn || !data.companyName) {
      throw new FormError("No ACN or company name value");
    }

    await this._companyStore.updateAcn(data.acn);
    await this._companyStore.updateName(data.companyName);
  }

  private async _updateAbnStartDate(data: CompanyAbnFormValues): Promise<void> {
    if (!data.startDate) {
      throw new FormError("No ABN start date value");
    }

    await this._companyStore.updateAbnStartDate(formatDateYYYYMMDD(data.startDate));
  }

  private async _updateAgriculturalOperations(data: CompanyAbnFormValues): Promise<void> {
    if (typeof data.isConductAgriculturalPropertyOperations === "undefined") {
      throw new FormError("No agricultural operations value");
    }

    await this._companyStore.updateAgriculturalPropertyOperations(data.isConductAgriculturalPropertyOperations);
  }

  private async _updateBusinessActivity(data: CompanyAbnFormValues): Promise<void> {
    if (!data.businessActivities) {
      throw new FormError("No business activities value");
    }

    const currentActivities = this._companyBusinessActivitiesStore.activities;

    for (const activity of currentActivities) {
      await this._companyBusinessActivitiesStore.deleteActivity(activity.businessActivityId);
    }

    for (const activityValue of data.businessActivities) {
      if (!activityValue.address) {
        throw new FormError("No business activity address value");
      }

      await this._companyBusinessActivitiesStore.createActivity(activityValue.id, activityValue.address);
    }
  }
}
